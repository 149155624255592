<section id="contact" class="contact-area ptb_100">
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-12 col-lg-5">
        <!-- Section Heading -->
        <div class="section-heading text-center mb-3">
          <h2>L'histoire commence ici!</h2>
                    <p class="d-none d-sm-block mt-4">Envoyez nous tous vos besoins et nous serons heureux de vous servir. On est la pour vous.</p>
                    <p class="d-block d-sm-none mt-4">Envoyez nous tous vos besoins et nous serons heureux de vous servir. On est la pour vous.</p>
        </div>
        <!-- Contact Us -->
        <div class="contact-us">
          <ul>
            <!-- Contact Info -->
                        <li class="contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3">
              <span><i class="fas fa-mobile-alt fa-3x"></i></span>
              <a class="d-block my-2" href="#">
                <h3>+1 (418) 906-6380</h3>
              </a>
              <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> -->
            </li>
            <!-- Contact Info -->
                        <li class="contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3">
              <span><i class="fas fa-envelope-open-text fa-3x"></i></span>
              <a class="d-none d-sm-block my-2" href="#">
                <h5 class="emailtextfont">info@innovationstechnologique.com</h5>
              </a>
              <a class="d-block d-sm-none my-2" href="#">
                <h5 class="emailtextfont">info@innovationstechnologique.com</h5>
              </a>
                            <p>N'hésiter pas de nous contacter pour savoir plus d'informations sur nos services.</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 col-lg-6 pt-4 pt-lg-0">
        <!-- Contact Box -->
        <div class="contact-box text-center">
          <!-- Contact Form -->
          <form
            id="contact-form"
            *ngIf="!boaleanMessageSended"
            [formGroup]="formGroupContact"
            (ngSubmit)="onSubmit()"
          >
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <input
                    maxlength="100"
                    type="text"
                    class="form-control"
                    name="name"
                    placeholder="Name"
                    required
                    formControlName="nom"
                    [ngClass]="{
                      'is-invalid ':
                        formGroupContact.get('nom').errors &&
                        (formGroupContact.get('nom').touched || submitted),
                      'is-valid':
                        !formGroupContact.get('nom').errors &&
                        (formGroupContact.get('nom').touched || submitted)
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      formGroupContact.get('nom').errors &&
                      (formGroupContact.get('nom').touched || submitted)
                    "
                  >
                    Name is required
                  </div>
                </div>
                <div class="form-group">
                  <input
                    maxlength="100"
                    type="text"
                    class="form-control"
                    name="nameCompany"
                    placeholder="Company Name"
                    formControlName="companyname"
                    [ngClass]="{
                      'is-invalid ':
                        formGroupContact.get('companyname').errors &&
                        (formGroupContact.get('companyname').touched ||
                          submitted),
                      'is-valid':
                        !formGroupContact.get('companyname').errors &&
                        (formGroupContact.get('companyname').touched ||
                          submitted)
                    }"
                  />
                </div>
                <div class="form-group">
                  <input
                    maxlength="70"
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="Email"
                    required="required"
                    formControlName="email"
                    [ngClass]="{
                      'is-invalid ':
                        formGroupContact.get('email').errors &&
                        (formGroupContact.get('email').touched || submitted),
                      'is-valid':
                        !formGroupContact.get('email').errors &&
                        (formGroupContact.get('email').touched || submitted)
                    }"
                  />

                  <div
                    class="invalid-feedback"
                    *ngIf="
                      formGroupContact.get('email')?.errors?.required &&
                      (formGroupContact.get('email')?.touched || submitted)
                    "
                  >
                    Email is required
                  </div>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      formGroupContact.get('email')?.errors?.pattern &&
                      (formGroupContact.get('email')?.touched || submitted)
                    "
                  >
                    Email is invalid
                  </div>
                  
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    [pattern]="limitValuePattern"
                    class="form-control"
                    name="phone"
                    placeholder="Phone"
                    required="required"
                    formControlName="phone"
                    [ngClass]="{
                      'is-invalid ':
                        formGroupContact.get('phone').errors &&
                        (formGroupContact.get('phone').touched || submitted),
                      'is-valid':
                        !formGroupContact.get('phone').errors &&
                        (formGroupContact.get('phone').touched || submitted)
                    }"
                  />
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      formGroupContact.get('phone').errors &&
                      (formGroupContact.get('phone').touched || submitted)
                    "
                  >
                    phone is required
                  </div>
                </div>
                <div class="input-group">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="form-control custom-file-input"
                      id="inputGroupFile01"
                      aria-describedby="inputGroupFileAddon01"
                      formControlName="attachments"
                      (change)="onFileSelected($event)"
                      [ngClass]="{
                        'is-invalid ':
                          formGroupContact.get('attachments').errors &&
                          (formGroupContact.get('attachments').touched ||
                            submitted),
                        'is-valid':
                          !formGroupContact.get('attachments').errors &&
                          (formGroupContact.get('attachments').touched ||
                            submitted)
                      }"
                    />
                    <label class="custom-file-label" for="inputGroupFile01">{{
                      fileName ? fileName : "Send your CV"
                    }}</label>
                    <label
                      class="invalid-feedback"
                      class="custom-file-label"
                      for="inputGroupFile01"
                      *ngIf="
                        formGroupContact.get('attachments').errors &&
                        (formGroupContact.get('attachments').touched ||
                          submitted)
                      "
                    >
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-2">
                <div class="form-group">
                  <textarea
                    maxlength="3000"
                    class="form-control"
                    name="message"
                    placeholder="Message"
                    required="required"
                    formControlName="message"
                    [ngClass]="{
                      'is-invalid ':
                        formGroupContact.get('message').errors &&
                        (formGroupContact.get('message').touched || submitted),
                      'is-valid':
                        !formGroupContact.get('message').errors &&
                        (formGroupContact.get('message').touched || submitted)
                    }"
                  ></textarea>
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      formGroupContact.get('message').errors &&
                      (formGroupContact.get('message').touched || submitted)
                    "
                  >
                    Message is required
                  </div>
                </div>
              </div>
              <div class="col-12">
                <button class="btn btn-bordered active btn-block mt-3">
                  <span class="text-white pr-3"
                    ><i class="fas fa-paper-plane"></i></span
                  >Envoyer une demande
                </button>
              </div>
            </div>
          </form>
          <!-- <p class="form-message"></p> -->
          <div *ngIf="boaleanMessageSended">
                        <h2>Nous avons bien reçu votre demande. Nous vous répondrons le plus vite possible.</h2>
          </div>
          <div class="col-12" *ngIf="boaleanMessageSended">
                        <button (click)="changeboaleanMessageSendedToFalse()" class="btn btn-bordered active btn-block mt-3"><span class="text-white pr-3"><i class="fas fa-paper-plane"></i></span>Envoyer une autre demande</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>