<section id="about" class="section content-area ptb_150">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6">
                <!-- Profile Circle Wrapper -->
                <div class="profile-circle-wrapper circle-animation d-none d-sm-block">
                    <!-- Profile Inner -->
                    <div class="profile-inner">
                        <!-- Profile Circle -->
                        <div class="profile-circle circle-lg">
                            <span class="profile-icon icon-1">
                                <img class="icon-1-img" src="assets/img/profile_icon_1.svg" alt="" />
                            </span>
                            <span class="profile-icon icon-2">
                                <img class="icon-2-img" src="assets/img/profile_icon_2.svg" alt="" />
                            </span>
                            <span class="profile-icon icon-3">
                                <img class="icon-3-img" src="assets/img/profile_icon_1.svg" alt="" />
                            </span>
                            <span class="profile-icon icon-4">
                                <img class="icon-4-img" src="assets/img/profile_icon_2.svg" alt="" />
                            </span>
                        </div>

                        <!-- Profile Circle -->
                        <div class="profile-circle circle-md">
                            <span class="profile-icon icon-5">
                                <img class="icon-5-img" src="assets/img/profile_icon_3.svg" alt="" />
                            </span>
                            <span class="profile-icon icon-6">
                                <img class="icon-6-img" src="assets/img/profile_icon_3.svg" alt="" />
                            </span>
                            <span class="profile-icon icon-7">
                                <img class="icon-7-img" src="assets/img/profile_icon_3.svg" alt="" />
                            </span>
                        </div>

                        <!-- Profile Circle -->
                        <div class="profile-circle circle-sm">
                            <span class="profile-icon icon-8">
                                <img class="icon-8-img" src="assets/img/profile_icon_4.svg" alt="" />
                            </span>
                            <span class="profile-icon icon-9">
                                <img class="icon-9-img" src="assets/img/profile_icon_4.svg" alt="" />
                            </span>
                        </div>
                    </div>
                    <img class="folder-img" src="assets/img/newImg/design.png" alt="" style="width: 200%; height: 200%; top: -130px; left: 0px" />
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- Content Inner -->
                <div class="content-inner text-center pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                    <!-- Section Heading -->
                    <div class="section-heading text-center mb-3">
                        <h2>Pourquoi nous choisir</h2>
                        <p class="d-none d-sm-block mt-4">Nous ferons toujours les efforts nécessaires pour dépasser vos attentes. Nous travaillons avec la méthodologie agile qui nous permet de rester flexible et toujours connecté avec vous. De cette façon, tous changements, améliorations et maintenances dans vos projets deviennent plus facile à y intégrer.</p>
                        <p class="d-block d-sm-none mt-4">Nous ferons toujours les efforts nécessaires pour dépasser vos attentes. Nous travaillons avec la méthodologie agile qui nous permet de rester flexible et toujours connecté avec vous. De cette façon, tous changements, améliorations et maintenances dans vos projets deviennent plus facile à y intégrer.</p>
                    </div>
                    <!-- Content List -->
                    <ul class="content-list text-left">
                        <!-- Single Content List -->
                        <li class="single-content-list media py-2">
                            <div class="content-icon pr-4">
                                <span class="color-2"><i class="fas fa-angle-double-right"></i></span>
                            </div>
                            <div class="content-text media-body">
                                <span><b>Work smarter, not harder.</b><br>Apprendre à travailler plus intelligemment, pas plus, améliore la productivité et les performances tout en augmentant la satisfaction de notre client. Cela peut également faire de nous une équipe execptionnelle.</span>
                            </div>
                        </li>
                        <!-- Single Content List -->
                        <li class="single-content-list media py-2">
                            <div class="content-icon pr-4">
                                <span class="color-2"><i class="fas fa-angle-double-right"></i></span>
                            </div>
                            <div class="content-text media-body">
                                <span><b>On fait tout le travail pour vous</b><br>Quelque soit votre besoin, quelque soit l'avancement de votre projet, nous ferons le travail pour vous. À partir de trouver un besoin ou une idée innovante jusqu'à la livraison et aussi la maintenance de vos projets.</span>
                            </div>
                        </li>
                        <!-- Single Content List -->
                        <li class="single-content-list media py-2">
                            <div class="content-icon pr-4">
                                <span class="color-2"><i class="fas fa-angle-double-right"></i></span>
                            </div>
                            <div class="content-text media-body">
                                <span><b>La nouvelle technologie</b><br>Nous cherchons toujours à répondre à vos besoins et aussi à intégrer les nouvelles technologies dans vos projets. A nos jours, la technologie s'évolue rapidement, se complexifie et se multiplie. C'est notre rôle de chercher les innovations pertinentes qu'on peut les intégrer dans vos projets.</span>
                            </div>
                        </li>
                    </ul>
                    <!-- <a href="#" class="btn btn-bordered mt-4">Learn More</a> -->
                </div>
            </div>
        </div>
    </div>
</section>