import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { POSTS } from "./posts";
@Component({
  selector: "app-posts",
  templateUrl: "./posts.component.html",
  styleUrls: ["./posts.component.css"],
})
export class PostsComponent implements OnInit {
  posts = POSTS
  postInfo: any 
  constructor(private route:ActivatedRoute,private router:Router) {}

  ngOnInit(): void {
  }

  seeMoreInfo(post: any) {
    this.router.navigate(['post', post.id]);
  }
}
