 //status : actif - notActif
 export const POSTS = [
    { 
      id:5266428901,
      jobPublication: "Date de publication : 10 janvier 2023",
      jobTitle: "Programmeur C#",
      status:"notActif",
      jobDescription:
        "La  société d’innovations et des solutions technologiques inc. est à la recherche d’un programmeur avec une expérience solide en .Net et particulièrement en C# d’au moins 2 ans. Le programmeur participera dans des projets internes et externes afin de répondre aux besoins de nos clients. Le travail peut se faire en présentiel ou/et à distance selon le projet.",
      jobImage:'',
        details: [
        {
          title: "Rôle et Responsabilités",
          tasks: [
            "Participer à la conception du projet (pour les nouveaux projets)",
            "Participer au développement des fonctionnalités durant les différentes phases du projet",
            "Participer au support Prod pour les projets existants,",
            "Participer à la correction des anomalies et des bugs",
            "Développer, mettre à jour et exécuter les tests unitaires",
            "Participer à la rédaction des documentations techniques et fonctionnelles",
            "Assister à la création et mise à jour de la base de données",
            "Communiquer avec les membres de l’équipe et les parties prenantes afin de faciliter l’avancement des travaux",
            "Participer aux différentes cérémonies du cadre de travail (Scrum, Canban, Safe, etc.)",
            "Proposer des idées innovantes pour développer des nouvelles fonctionnalités ou pour améliorer les fonctionnalités existantes",
          ],
        },
        {
          title: "Exigences et qualifications",
          tasks: [
            "Baccalauréat en science informatique ou plus (minimum 2 ans en post-secondaire)",
            "3 ans d’expérience ou plus en IT",
            "2 ans d’expérience ou plus en C#",
            "Excellentes connaissances en développement C# Frondend et Backend, .NET 6, et SQL",
            "Bonne connaissance en AP.NET, VB, HTML, XML, CSS et Bootstrap, Javasript, JSON, Oracle",
            "Bonne connaissance avec la plupart des outils suivants : Visual Studio, Oracle, MySQL, Postman ou SoapUI,IIS",
            "Avoir déjà travailler en mode Agile (Scrum, Canban ou Safe)",
            "Avoir déjà travailler avec l’outil de gestion Azure devops ou Jira",
            "Maitrise les outils Git (Github, Gitlab, ou bitbucket)",
            "Maitrise les différentes architectures des logiciels (MVC, MVVM, N tiers, etc…)",
            "Maitrise les conceptions UML",
            "Niveau avancé en français (orale et écrit)",
            "Niveau intermédiaire en anglais (orale et écrit)",
            "Avoir un bon esprit d’équipe.",
          ],
        },
        {
          title: "Expérience supplémentaire souhaitée",
          tasks: [
            "Avoir des connaissances en Angular, Nodejs, Java, Android, IOS et Flutter",
          ],
        },
        {
          title: "Avantages",
          tasks: [
            "Salaire exceptionnel par rapport à d’autres sociétés en informatique",
            "Une allocation annuelle de 1000$ pour les équipements nécessaires au travail",
            "3 à 4 semaines de vacances selon l’expérience",
            "Assurances collectives",
            "Bonification annuelle pourra aller jusqu’à 10% du salaire annuel.",
          ],
        },
      ],
    },
    { 
      id:5266428902,
      jobPublication: "Date de publication : 26 février 2023",
      jobTitle: "Programmeur Java",
      status:"notActif",
      jobDescription:
        "La  société d’innovations et des solutions technologiques inc. est à la recherche d’un programmeur avec une expérience solide en Java d’au moins 2 ans. Le programmeur participera dans des projets internes et externes afin de répondre aux besoins de nos clients. Le travail peut se faire en présentiel ou/et à distance selon le projet.",
      jobImage:'',
        details: [
        {
          title: "Rôle et Responsabilités",
          tasks: [
            "Participer à la conception du projet (pour les nouveaux projets)",
            "Participer au développement des fonctionnalités durant les différentes phases du projet",
            "Participer au support Prod pour les projets existants,",
            "Participer à la correction des anomalies et des bugs",
            "Développer, mettre à jour et exécuter les tests unitaires",
            "Participer à la rédaction des documentations techniques et fonctionnelles",
            "Assister à la création et mise à jour de la base de données",
            "Communiquer avec les membres de l’équipe et les parties prenantes afin de faciliter l’avancement des travaux",
            "Participer aux différentes cérémonies du cadre de travail (Scrum, Canban, Safe, etc.)",
            "Proposer des idées innovantes pour développer des nouvelles fonctionnalités ou pour améliorer les fonctionnalités existantes",
          ],
        },
        {
          title: "Exigences et qualifications",
          tasks: [
            "Baccalauréat en science informatique ou plus (minimum 2 ans en post-secondaire)",
            "3 ans d’expérience ou plus en IT",
            "2 ans d’expérience ou plus en Java",
            "Bonne connaissance HTML, XML, CSS et Bootstrap, Javasript, JSON, Oracle",
            "Bonne connaissance avec la plupart des outils suivants : Eclipse, NetBeans, IntelliJ, Visual Studio, Oracle, MySQL, Postman ou SoapUI,IIS",
            "Avoir déjà travailler en mode Agile (Scrum, Canban ou Safe)",
            "Avoir déjà travailler avec l’outil de gestion Azure devops ou Jira",
            "Maitrise les outils Git (Github, Gitlab, ou bitbucket)",
            "Maitrise les différentes architectures des logiciels (MVC, MVVM, N tiers, etc…)",
            "Maitrise les conceptions UML",
            "Niveau avancé en français (orale et écrit)",
            "Niveau intermédiaire en anglais (orale et écrit)",
            "Avoir un bon esprit d’équipe.",
          ],
        },
        {
          title: "Expérience supplémentaire souhaitée",
          tasks: [
            "Avoir des connaissances en Angular, Nodejs, Java, Android, IOS et Flutter",
          ],
        },
        {
          title: "Avantages",
          tasks: [
            "Salaire exceptionnel par rapport à d’autres sociétés en informatique",
            "Une allocation annuelle de 1000$ pour les équipements nécessaires au travail",
            "3 à 4 semaines de vacances selon l’expérience",
            "Assurances collectives",
            "Bonification annuelle pourra aller jusqu’à 10% du salaire annuel.",
          ],
        },
      ],
    },
  ];