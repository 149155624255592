import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { emailInfo } from "src/app/services/emailInfo";
import { ServeurService } from "src/app/services/serveur.service";
@Component({
  selector: 'app-contact-one',
  templateUrl: './contact-one.component.html',
  styleUrls: ['./contact-one.component.css']
})
export class ContactOneComponent implements OnInit {
  formdata;
  formGroupContact: FormGroup;
  boaleanMessageSended: boolean = false;
  submitted: boolean = false;
  fileName: File = null;
  selectedFile: any;
  limitValuePattern = /^\d{0,15}$/;

  constructor(
    private service: ServeurService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formGroupContact = this.formBuilder.group({
      nom: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      companyname: [""],
      phone: ["", [Validators.required]],
      message: ["", [Validators.required]],
      attachments: [null],
    });
  }
  public erreursForm: { [field: string]: string } = {
    nom: "",
    email: "",
    companyname: "",
    phone: "",
    message: "",
    attachments: null,
  };
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.fileName = event.target.files[0].name;
  }

  sendEmailusinAPI() {
    if (this.formGroupContact.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append("file", this.selectedFile);
    formData.append("name", this.formGroupContact.get("nom").value);
    formData.append("email", this.formGroupContact.get("email").value);
    formData.append(
      "companyname",
      this.formGroupContact.get("companyname").value
    );
    formData.append("phone", this.formGroupContact.get("phone").value);
    formData.append("message", this.formGroupContact.get("message").value);
    formData.append("senderAddress", emailInfo.contact.senderAddress);
    formData.append("recipients", emailInfo.contact.recipients);
    this.service.sendEmail(formData).subscribe(
      (response) => {
        //console.log("Response from API is", response);
      },
      (error) => {
        if (error.status == 200) {
          this.boaleanMessageSended = true;
        }
      }
    );
  }
  onSubmit() {
    this.submitted = true;
    this.sendEmailusinAPI();
  }
  changeboaleanMessageSendedToFalse() {
    this.boaleanMessageSended = false;
    this.submitted = false;
    this.fileName = null;
    this.formGroupContact.reset();
  }
}
