import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { emailInfo } from "src/app/services/emailInfo";
import { ServeurService } from "src/app/services/serveur.service";
import { POSTS } from "../posts";

@Component({
  selector: "app-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.css"],
})
export class PostComponent implements OnInit {
  posts = POSTS;
  param;
  postInfo: any;
  formPost: FormGroup;
  boaleanMessageSended: boolean = false;
  submitted: boolean = false;
  fileName = null;
  selectedFile: File;
  limitValuePattern = /^\d{0,15}$/;
  constructor(
    private service: ServeurService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.formPost = this.formBuilder.group({
      nom: ["", [Validators.required]],
      email: ["", [Validators.required,Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]],
      companyname: [""],
      phone: ["", [Validators.required]],
      message: ["", [Validators.required]],
      attachments: [""],
    });
  }

  ngOnInit(): void {
    this.param = this.route.snapshot.params
      ? this.route.snapshot.params.id
      : null;
    if(this.param){
     this.postInfo = this.posts.filter((post) => post.id == this.param)[0];
    }
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name;
  }
  onSubmit() {
    this.submitted = true;
    this.sendEmailusinAPI();
  }
  sendEmailusinAPI() {
    if (this.formPost.invalid) {
      return;
    }
    let formData = new FormData();
    formData.append("file", this.selectedFile);
    formData.append("name", this.formPost.get("nom").value);
    formData.append("companyname", this.formPost.get("companyname").value);
    formData.append("email", this.formPost.get("email").value);
    formData.append("phone", this.formPost.get("phone").value);
    formData.append("message", this.formPost.get("message").value);
    formData.append("postTitle", this.postInfo.jobTitle);
    formData.append("senderAddress", emailInfo.post.senderAddress);
    formData.append("recipients", emailInfo.post.recipients);
    this.service.sendEmail(formData).subscribe(
      (response) => {
        //console.log("Response from API is", response);
      },
      (error) => {
        //console.log("Error is", error);
        if (error.status == 200) {
          this.boaleanMessageSended = true;
        }
      }
    );
    this.boaleanMessageSended = true;
  }

  changeboaleanMessageSendedToFalse() {
    this.boaleanMessageSended = false;
    this.submitted = false;
    this.fileName = null;
    this.formPost.reset();
  }

  backToPosts() {
    this.router.navigate(["posts"], { fragment: "posts" });
  }
}
