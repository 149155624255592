<section id="services" class="section service-area bg-grey ptb_150">
    <!-- Shape Top -->
    <div class="shape shape-top">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Nous fournissons les meilleurs services numériques</h2>
                    <p class="d-none d-sm-block mt-4">Le monde de la technologie de l'information est vaste. Nous offrons des services dans plusieurs parties en IT pour être créatif et polyvalent.</p>
                    <p class="d-block d-sm-none mt-4">Le monde de la technologie de l'information est vaste. Nous offrons des services dans plusieurs parties en IT pour être créatif et polyvalent.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4" *ngFor="let service of services">
                <!-- Single Service -->
                <div class="single-service p-4">
                    <span [class]="service.class"></span>
                    <h3 class="my-3">{{service?.title}}</h3>
                    <p>{{service?.description}}</p>
                    <!-- <a class="service-btn mt-3" href="#">Learn More</a> -->
                </div>
            </div>
        </div>
    </div>
    <br>
    <div class="container">
        <div class="row justify-content-center">
            <div>
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2>Nous travaillons avec les technologies suivantes</h2>
                    <!-- <p class="d-none d-sm-block mt-4">Le monde de la technologie de l'information est vaste. Nous offrons des services dans plusieurs partie en IT pour être créatif et polyvalent.</p>
                    <p class="d-block d-sm-none mt-4">Le monde de la technologie de l'information est vaste. Nous offrons des services dans plusieurs partie en IT pour être créatif et polyvalent.</p> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4 col-md-4 col-lg-3 iconLanguageSmallMedium iconLanguageSmall" *ngFor="let tech of techTab1">
                <!-- Single Service -->
                <div class="single-service p-4" >
                    <img class="folder-img-language imageRounded" [src]="tech?.imagePath" alt="" />
                    <h3 class="textLanguage">{{tech?.name}}</h3>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showMoreItemsBool">
            <div class="col-4 col-md-4 col-lg-3 iconLanguageSmallMedium iconLanguageSmall" *ngFor="let tech of techTab2" >
                <!-- Single Service -->
                <div class="single-service p-4" >
                    <img class="folder-img-language imageRounded" [src]="tech?.imagePath" alt=""  />
                    <h3 class="textLanguage">{{tech?.name}}</h3>
                </div>
            </div>            
        </div>
        <div class="row justify-content-center">
            <div class="col-4 col-md-3 col-lg-2">
                <button (click)="showMoreItems()" class="btn btn-bordered active btn-block mt-3"><span class="text-white"></span>{{buttonText}}</button>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
</section>