<section class="section cta-area bg-overlay ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10">
                <!-- Section Heading -->
                <div class="section-heading text-center m-0">
                    <h2 class="text-white">Cherchez vous la meilleur société pour vos besoins?</h2>
                    <p class="text-white d-none d-sm-block mt-4">La solution peut être avec nous. Demander un devis gratuitement et nous vous répondrons le plus vite possible. <br>Nous serons heureux de vous voir avec nous.</p>
                    <p class="text-white d-block d-sm-none mt-4">La solution peut être avec nous. Demander un devis gratuitement et nous vous répondrons le plus vite possible. <br>Nous serons heureux de vous voir avec nous.</p>
                    <a href="#contact"  class=" scroll btn btn-bordered-white mt-4">Contacter nous</a>
                </div>
            </div>
        </div>
    </div>
</section>