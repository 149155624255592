<header id="header">
    <!-- Navbar -->
    <nav data-aos="zoom-out" data-aos-delay="800" class="navbar navbar-expand">
        <div class="container header">
            <!-- Navbar Brand-->
            <a class="navbar-brand" href="/">
                <img class="navbar-brand-regular" src="assets/img/newImg/logo_ITSC2.png" alt="brand-logo">
                <img class="navbar-brand-sticky" src="assets/img/newImg/logo_ITSC2.png" alt="sticky brand-logo">
            </a>
            <div class="ml-auto"></div>
            <!-- Navbar -->
            <ul class="navbar-nav items">
                <li class="nav-item">
                    <a class="nav-link scroll" fragment="home" [routerLink]="['/home']">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" fragment="Portfolio" [routerLink]="['/home']">Portfolio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" fragment="services" href="#services" [routerLink]="['/home']">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" fragment="about" href="#about" [routerLink]="['/home']">About</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" fragment="contact" href="#contact" [routerLink]="['/home']">Contact</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link scroll" fragment="posts" [routerLink]="['/posts']" >Posts</a>
                </li>
            </ul>
            <!-- Navbar Icons -->
            <!-- <ul class="navbar-nav icons">
                <li class="nav-item">
                    <a href="#" class="nav-link" data-toggle="modal" data-target="#search">
                        <i class="fas fa-search"></i>
                    </a>
                </li>
                <li class="nav-item social">
                    <a href="#" class="nav-link"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="nav-item social">
                    <a href="#" class="nav-link"><i class="fab fa-twitter"></i></a>
                </li>
            </ul> -->

            <!-- Navbar Toggler -->
            <ul class="navbar-nav toggle">
                <li class="nav-item">
                    <a href="#" class="nav-link" data-toggle="modal" data-target="#menu">
                        <i class="fas fa-bars toggle-icon m-0"></i>
                    </a>
                </li>
            </ul>

            <!-- Navbar Action Button -->
            <ul class="navbar-nav action">
                <li class="nav-item ml-3">
                    <a fragment="contact" href="#contact" [routerLink]="['/home']" class="scroll btn ml-lg-auto btn-bordered-white"><i class="fas fa-paper-plane contact-icon mr-md-2"></i>Demander un devis</a>
                </li>
            </ul>
        </div>
    </nav>
</header>