import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-one',
  templateUrl: './service-one.component.html',
  styleUrls: ['./service-one.component.css']
})
export class ServiceOneComponent implements OnInit {
  showMoreItemsBool: boolean = false;
  buttonText:string = "voir plus";
  techTab1=[
    {
      name:'HTML',
      imagePath:'assets/img/newImg/html.png'
    },
    {
      name:'CSS',
      imagePath:'assets/img/newImg/css.png'
    },
    {
      name:'JS',
      imagePath:'assets/img/newImg/javascript.png'
    },
    {
      name:'JQUERY',
      imagePath:'assets/img/newImg/jquery.png'
    },
    {
      name:'VB',
      imagePath:'assets/img/newImg/vb.png'
    },
    {
      name:'ASP.NET',
      imagePath:'assets/img/newImg/aspnet.jfif'
    },
    {
      name:'NODEJS',
      imagePath:'assets/img/newImg/nodejs.png'
    },
    {
      name:'ANDROID',
      imagePath:'assets/img/newImg/android.png'
    },
    {
      name:'BOOTSTRAP',
      imagePath:'assets/img/newImg/bootstrap.png'
    },
    {
      name:'IOS',
      imagePath:'assets/img/newImg/ios.png'
    },
    {
      name:'PHP',
      imagePath:'assets/img/newImg/php.png'
    },
    {
      name:'SQL',
      imagePath:'assets/img/newImg/sql.png'
    },
    {
      name:'MONGODB',
      imagePath:'assets/img/newImg/mongodb.png'
    },
    {
      name:'RESTAPI',
      imagePath:'assets/img/newImg/restapi.png'
    },
    {
      name:'UML',
      imagePath:'assets/img/newImg/uml.png'
    },
    {
      name:'XML',
      imagePath:'assets/img/newImg/xml.png'
    },
    {
      name:'AJAX',
      imagePath:'assets/img/newImg/ajax.png'
    },
    {
      name:'RFID',
      imagePath:'assets/img/newImg/rfid.png'
    },
    {
      name:'JAVA',
      imagePath:'assets/img/newImg/java.png'
    },
    {
      name:'ARDUINO',
      imagePath:'assets/img/newImg/arduino.png'
    },
    {
      name:'RASPBERRY PI',
      imagePath:'assets/img/newImg/raspberrypi.png'
    },
    {
      name:'WORD PRESS',
      imagePath:'assets/img/newImg/wordpress.png'
    }
  ]
  techTab2=[
    {
      name:'IIS',
      imagePath:'assets/img/newImg/iis.png'
    },{
      name:'MYSQL',
      imagePath:'assets/img/newImg/mysql.png'
    },{
      name:'ORACLE',
      imagePath:'assets/img/newImg/oracle.png'
    },{
      name:'BIZTALK',
      imagePath:'assets/img/newImg/biztalk.png'
    },{
      name:'AZURE DEVOPS',
      imagePath:'assets/img/newImg/azuredevops.png'
    },{
      name:'APACHE',
      imagePath:'assets/img/newImg/apache.png'
    },{
      name:'XMAPP',
      imagePath:'assets/img/newImg/xampp.png'
    },{
      name:'PUTTY',
      imagePath:'assets/img/newImg/putty.png'
    },{
      name:'FIGMA',
      imagePath:'assets/img/newImg/figma.png'
    },
  ]
  services=[
    {
      class:'flaticon-rocket-launch color-1 icon-bg-1',
      title:'Analyse des données',
      description:"Parfoit une tâche d'analyse des données pourra prendre beaucoup du temps. Avec notre expertise, nous pouvons vous aider à analyser vos données de façon sécuritaire, pertinente et plus vite possible afin de gagner de l'argent et du temps."
    },
    {
      class:'flaticon-monitoring color-2 icon-bg-2',
      title:'Création du site Web',
      description:"Vous voulez crée votre propre site web. Vous avez deja un site web et vous voulez ajouter des fonctionnalités ou de corriger des bugs ou faire de la maintenance. Vous voulez créer ou modifier une base de données. Vous cherchez quelqu'un pour controler votre site en cas de besoin. Nous sommes la pour vous."
    },
    {
      class:'flaticon-web color-3 icon-bg-3',
      title:'Configuration et maintenance des serveurs',
      description:"Pour deployer nos sites web et applications web et mobile, nous aurons toujours besoin d'un serveur. Nous s'occupons de votre hebergement dés le début jusqu'à la fin quelque soit pour l'installation, la configuration ou la maintenance de votre serveur."
    },
    {
      class:'flaticon-smartphone color-4 icon-bg-4',
      title:'Développment des applications bureautique, web et mobile',
      description:"Selon votre besoin, nous s'occupons du développement de votre application à partir de la conception jusqu'à le deploiement. Nous travaillons aussi avec des plateformes Hybrid pour implementer des applications en même temps web et mobile."
    },
    {
      class:'flaticon-email color-5 icon-bg-5',
      title:'Marketing',
      description:"Vous voulez promouvoir votre site web, vos produits ou vos projets, nous pouvons vous aider avec notre expertise par les differents sorte du Marketing tel que Email Marketing, Affiliation Marketing, téléMarketing ..etc."
    },
    {
      class:'flaticon-affiliate color-6 icon-bg-6',
      title:'Conception et réalisation des produits éléctroniques et mécaniques',
      description:"Vous avez une idée de produit, notre expertise en informatique, éléctonique et mécanique nous aide à répondre à vos besoins. Pour un prototype ou pour un produit final, nous engageons à vous réaliser vos produits en réspectant une bonne qualité et en assurant la sécurité."
    },
  ]
  constructor() {}

  ngOnInit(): void {}

  showMoreItems()
  {
    this.showMoreItemsBool = !this.showMoreItemsBool;
    if(this.showMoreItemsBool == true)
    {
      this.buttonText = "voir moins";
    }
    if(this.showMoreItemsBool == false)
    {
      this.buttonText = "voir plus";
    }

  }

}
