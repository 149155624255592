import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroOneComponent } from './components/hero/hero-one/hero-one.component';
import { ContentOneComponent } from './components/content/content-one/content-one.component';
import { ContentFourComponent } from './components/content/content-four/content-four.component';
import { ServiceOneComponent } from './components/services/service-one/service-one.component';
import { ContactOneComponent } from './components/contact/contact-one/contact-one.component';
import { CtaComponent } from './components/cta/cta.component';
import { FooterOneComponent } from './components/footer/footer-one/footer-one.component';
import { ModalSearchComponent } from './components/modal/modal-search/modal-search.component';
import { ModalMenuComponent } from './components/modal/modal-menu/modal-menu.component';
import { ScrollupComponent } from './components/scrollup/scrollup.component';
import { HeaderThreeComponent } from './components/header/header-three/header-three.component';
import { ThemeSevenComponent } from './themes/theme-seven/theme-seven.component';
import { HomeComponent } from './components/home/home.component';
import { PostsModule } from './components/posts/posts.module';

@NgModule({
  declarations: [
    AppComponent,
    HeroOneComponent,
    ContentOneComponent,
    ContentFourComponent,
    ServiceOneComponent,
    ContactOneComponent,
    CtaComponent,
    FooterOneComponent,
    ModalSearchComponent,
    ModalMenuComponent,
    ScrollupComponent,
    HeaderThreeComponent,
    ThemeSevenComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    PostsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
