import { Injectable } from '@angular/core';
import {WebRequestService} from 'src/app/web-request.service';

@Injectable({
  providedIn: 'root'
})
export class ServeurService {

  constructor(private webRequestService : WebRequestService) { }

  // sendEmail(name: string,companyname:string,emailclient:string,phone:string,message:string,attachments?: File ){
  //   message = message.replace(new RegExp('/', 'g'), ' ');
  //   const params = '/' + name + '/' + companyname + '/' + emailclient + '/' + phone + '/' + message;
  //   const formData = new FormData();
  //   formData.append('attachments', attachments);
  //   return this.webRequestService.post('/email' + params , formData);
  // }
  sendEmail(formData: FormData){
    return this.webRequestService.post(`email`, formData);
  }

}
