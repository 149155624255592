<section id="posts" class="section service-area bg-grey ptb_150">
    <!-- Shape Top -->
    <div class="shape shape-top">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
      ></path>
    </svg>
  </div>
  <div class="container pt-3 pb-3" [ngClass]="{'inActif': postInfo?.status == 'notActif'}">
    <div class="row justify-content-center">
      <div *ngIf="param">
        <div class="col-12 col-md-12 col-lg-12 section-heading text-center">
          <h2>{{postInfo.jobTitle}}</h2>
        </div>
        
        <div class="col-12 col-md-12 col-lg-12">
          <div class="col-md-6 col-lg-6 col-sm-12 danger" *ngIf="postInfo?.status == 'notActif'">
            <svg style="color: rgb(169, 68, 66);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="#a94442"></path> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="#a94442"></path> </svg>
            <span class="pl-2"> Cette Offre n'est plus disponible </span>
          </div>
          <span style="float: right;color: #9c47e4;"><b>{{postInfo.jobPublication}}</b></span><br>
          <div class="content-text" style="padding: 5px;">
            <span style="font-size: 16px;"><b>Description du poste</b></span>
          </div>
          <div class="content-text" style="padding: 5px;">
            <span>{{ postInfo?.jobDescription }}</span>
          </div>
        </div>
        <div
          class="col-12 col-md-12 col-lg-12"
          *ngFor="let post of postInfo?.details"
        >
          <ul class="content-list text-left">
            <!-- Single Content List -->
            <li class="single-content-list media py-2">
              <div class="row">
                <div class="col-12 col-md-12 col-lg-12">
                  <div class="content-icon pr-4">
                    <span class="color-2 pr-2"
                      ><i class="fas fa-angle-double-right"></i>
                    </span>
                    <span
                      ><b>{{ post?.title }}</b></span
                    >
                  </div>
                </div>
                <div class="col-12 col-md-12 col-lg-12 pl-4">
                  <ul class="content-list text-left">
                    <li
                      class="single-content-list media py-2"
                      *ngFor="let task of post?.tasks"
                    >
                      <div class="content-icon pr-3">
                        <span class="color-2"><i class="fas fa-plus"></i></span>
                      </div>
                      <div class="content-text">
                        <p>{{ task }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-12 col-lg-12 text-center" *ngIf="postInfo?.status == 'actif'">
          <br>
          <h3>Pour postuler votre candidature, vous pouvez remplir le formulaire suivant ou envoyer un courriel à Hr.itsc@innovationstechnologique.com</h3>
          <br>
        </div>
        <div class="col-12 col-md-12 col-lg-12 pt-lg-0 border" *ngIf="postInfo?.status == 'actif'">
          <!-- Contact Box -->
          <div class="contact-box text-center p-4">
            <!-- Contact Form -->
             <form
              id="contact-form"
              *ngIf="!boaleanMessageSended"
              (ngSubmit)="onSubmit()"
              [formGroup]="formPost"
            >
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="form-group">
                    <input
                      maxlength="100"
                      type="text"
                      class="form-control"
                      name="nom"
                      placeholder="Name"
                      required="required"
                      formControlName="nom"
                      [ngClass]="{
                        'is-invalid':
                          formPost.get('nom').errors &&
                          (formPost.get('nom').touched || submitted),
                        'is-valid':
                          !formPost.get('nom').errors &&
                          (formPost.get('nom').touched || submitted)
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        formPost.get('nom').errors &&
                        (formPost.get('nom').touched || submitted)
                      "
                    >
                      Name is required
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      maxlength="100"
                      type="text"
                      class="form-control"
                      name="nameCompany"
                      placeholder="Company Name"
                      formControlName="companyname"
                      [ngClass]="{
                        'is-invalid':
                          formPost.get('companyname').errors &&
                          (formPost.get('companyname').touched || submitted),
                        'is-valid':
                          !formPost.get('companyname').errors &&
                          (formPost.get('companyname').touched || submitted)
                      }"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      maxlength="70"
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Email"
                      required="required"
                      formControlName="email"
                      [ngClass]="{
                        'is-invalid':
                          formPost.get('email')?.errors && 
                          (formPost.get('email').touched || submitted),
                        'is-valid':
                          !formPost.get('email')?.errors &&
                          (formPost.get('email')?.touched || submitted)
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        formPost.get('email')?.errors?.required &&
                        (formPost.get('email')?.touched || submitted)
                      "
                    >
                      Email is required
                    </div>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        formPost.get('email')?.errors?.pattern &&
                        (formPost.get('email')?.touched || submitted)
                      "
                    >
                      Email is invalid
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="number"
                      [pattern]="limitValuePattern"
                      class="form-control"
                      name="phone"
                      placeholder="Phone"
                      required="required"
                      formControlName="phone"
                      [ngClass]="{
                        'is-invalid':
                          formPost.get('phone').errors &&
                          (formPost.get('phone').touched || submitted),
                        'is-valid':
                          !formPost.get('phone').errors &&
                          (formPost.get('phone').touched || submitted)
                      }"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        formPost.get('phone').errors &&
                        (formPost.get('phone').touched || submitted)
                      "
                    >
                      Phone is required
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="custom-file">
                      <input
                        type="file"
                        class="form-control custom-file-input"
                        id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01"
                        (change)="onFileSelected($event)"
                        formControlName="attachments"
                        aria-label="file example"
                        [ngClass]="{
                          'is-invalid':
                            formPost.get('attachments').errors &&
                            (formPost.get('attachments').touched || submitted),
                          'is-valid':
                            !formPost.get('attachments').errors &&
                            (formPost.get('attachments').touched || submitted)
                        }"
                      />

                      <label class="custom-file-label" for="inputGroupFile01">
                        {{
                          fileName
                            ? fileName
                            : formPost.get("attachments").errors &&
                              (formPost.get("attachments").touched || submitted)
                            ? "CV is required"
                            : "Send your CV"
                        }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2">
                  <div class="form-group">
                    <textarea
                      maxlength="3000"
                      class="form-control"
                      name="message"
                      placeholder="Message"
                      required="required"
                      formControlName="message"
                      [ngClass]="{
                        'is-invalid':
                          formPost.get('message').errors &&
                          (formPost.get('message').touched || submitted),
                        'is-valid':
                          !formPost.get('message').errors &&
                          (formPost.get('message').touched || submitted)
                      }"
                    ></textarea>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        formPost.get('message').errors &&
                        (formPost.get('message').touched || submitted)
                      "
                    >
                      Message is required
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button class="btn btn-bordered active btn-block mt-3">
                    <span class="text-white pr-3"
                      ><i class="fas fa-paper-plane"></i></span
                    >Envoyer une demande
                  </button>
                </div>
              </div>
            </form>
            <!-- <p class="form-message"></p> -->
            <div *ngIf="boaleanMessageSended">
              <h2>
                Nous avons bien reçu votre demande. Nous vous répondrons le plus
                vite possible.
              </h2>
            </div>
            <div class="col-12" *ngIf="boaleanMessageSended">
              <button
                (click)="changeboaleanMessageSendedToFalse()"
                class="btn btn-bordered active btn-block mt-3"
              >
                <span class="text-white pr-3"
                  ><i class="fas fa-paper-plane"></i></span
                >Envoyer une autre demande
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12 pt-lg-0 show-more mt-3 pr-3">
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="backToPosts()"
          >
            Retour
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Shape Bottom -->
  <div class="shape shape-bottom">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 100"
      preserveAspectRatio="none"
      fill="#FFFFFF"
    >
      <path
        class="shape-fill"
        d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
</section>