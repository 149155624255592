<section id="posts" class="section service-area bg-grey ptb_150">
    <!-- Shape Top -->
    <div class="shape shape-top">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
            c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
            c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
    <div class="col-12 col-md-12 col-lg-12 text-center">
        <br>
        <h3>Liste des postes publiés</h3>
        <br>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-12">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-12 mx-2 my-2 card" style="width: 18rem;border-color: #8635da;"
                        *ngFor="let post of posts">
                        <img [src]="post?.jobImage" class="card-img-top" alt="..."
                            *ngIf="post.jobImage && post.jobImage!== ''  ">
                        <div class="card-body row">
                            <div class="col-md-7 col-lg-7 col-sm-12">
                                <h5 class="card-title">{{post.jobTitle}}</h5>
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 danger mb-2" *ngIf="post?.status =='notActif'">
                                <svg style="color: rgb(169, 68, 66);" xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                        fill="#a94442"></path>
                                    <path
                                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                                        fill="#a94442"></path>
                                </svg>
                                <span class="pl-2"> N'est pas disponible </span>
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 success mb-2" *ngIf="post?.status =='actif'">
                                <svg style="color: rgb(39, 98, 62);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="#27623e"></path> <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" fill="#27623e"></path> </svg>
                                <span class="pl-2"> Actif </span>
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12">
                                <p class="card-text">{{post.jobDescription}}</p>
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12">
                                <div class="show-more pt-2">
                                    <button type="button" class="btn btn-primary" (click)="seeMoreInfo(post)">Voir plus de
                                        détails</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape shape-bottom">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
            <path class="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
</section>